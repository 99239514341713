var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"formRules"},[_c('div',[_c('b-card',[_c('b-card-header',[_c('b-row',{staticClass:"w-100",attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-popover",rawName:"v-b-popover.hover.focus",value:(
                      '⚪️ La fiche d\'enquête sur les visiteurs internationaux - frontieres (tourisme récepteur) est éditable par le grand public et les agents annexes. ⚪️ Il permet de collecter les données sur les voyageurs et leur séjours. ⚪️ Le remplissage de la fiche ne nécessite pas une connexion ou la création d\'un compte.'
                    ),expression:"\n                      '⚪️ La fiche d\\'enquête sur les visiteurs internationaux - frontieres (tourisme récepteur) est éditable par le grand public et les agents annexes. ⚪️ Il permet de collecter les données sur les voyageurs et leur séjours. ⚪️ Le remplissage de la fiche ne nécessite pas une connexion ou la création d\\'un compte.'\n                    ",modifiers:{"hover":true,"focus":true}}],staticClass:"btn-icon rounded-circle",attrs:{"size":"lg","variant":"outline-primary","title":"Informations"}},[_c('feather-icon',{attrs:{"icon":"BellIcon"}})],1)],1)])]),_c('b-col',{attrs:{"md":"2"}},[_c('b-card-img',{staticClass:"rounded-0 my-2",attrs:{"src":require('@/assets/images/illustration/Globalization-pana.png'),"alt":"Image"}})],1),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"md":"10"}},[_c('div',[_c('br')]),_c('div',[_c('h1',{staticClass:"m-auto text-center"},[_vm._v(" FICHE D'ENQUETE SUR LES VISITEURS INTERNATIONAUX - FRONTIERES ")]),_c('h1',{staticClass:"m-auto text-center"},[_vm._v("TOURISME RÉCEPTEUR")])])])],1)],1),_c('b-row',[_c('b-col',{staticClass:"pb-2",attrs:{"lg":"12"}},[_c('div',[_c('b-card',[_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"1. Dans quel pays vivez-vous ?"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"type":"text","options":_vm.pays,"label":"name","reduce":function (option) { return option.name; }},model:{value:(_vm.form.paysResidence),callback:function ($$v) {_vm.$set(_vm.form, "paysResidence", $$v)},expression:"form.paysResidence"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"2. De quelle nationalité êtes-vous ?"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"type":"text","options":_vm.pays,"label":"name","reduce":function (option) { return option.name; }},model:{value:(_vm.form.nationalite),callback:function ($$v) {_vm.$set(_vm.form, "nationalite", $$v)},expression:"form.nationalite"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"12","label":"3. A quelle date êtes-vous arrivés au Bénin ?"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Date"},model:{value:(_vm.form.dateArriveeBenin),callback:function ($$v) {_vm.$set(_vm.form, "dateArriveeBenin", $$v)},expression:"form.dateArriveeBenin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"4. Avec combien de personnes, y compris vous-même, voyagez-vous en partageant les dépenses ?"}},[_c('div',{staticClass:"d-flex align-items-center gap-10"},[_c('validation-provider',{staticClass:"d-inline-block",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","min":"0"},model:{value:(_vm.form.coVoyageursAdultes),callback:function ($$v) {_vm.$set(_vm.form, "coVoyageursAdultes", _vm._n($$v))},expression:"form.coVoyageursAdultes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"d-inline-block"},[_vm._v("personnes dont")]),_c('validation-provider',{staticClass:"d-inline-block",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","min":"0"},model:{value:(_vm.form.coVoyageursEnfants),callback:function ($$v) {_vm.$set(_vm.form, "coVoyageursEnfants", _vm._n($$v))},expression:"form.coVoyageursEnfants"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',[_vm._v("enfants de moins de 18 ans.")])],1)])],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"5. Quel a été le motif de votre visite au Bénin ? (Choisissez une seule réponse)"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"stacked":""},model:{value:(_vm.form.motifVoyage.radio),callback:function ($$v) {_vm.$set(_vm.form.motifVoyage, "radio", $$v)},expression:"form.motifVoyage.radio"}},_vm._l((_vm.motifVoyage),function(motif,motifIndex){return _c('b-form-radio',{key:'motifVoyage' + motifIndex,attrs:{"value":motif,"name":"motifVoyage"}},[_vm._v(" "+_vm._s(motif.text)+" "),(motif.children)?_c('b-form-radio-group',{attrs:{"stacked":"","name":"motifVoyage"},model:{value:(_vm.form.motifVoyage.radio),callback:function ($$v) {_vm.$set(_vm.form.motifVoyage, "radio", $$v)},expression:"form.motifVoyage.radio"}},_vm._l((motif.children),function(childrenOption,index){return _c('b-form-radio',{key:'motifVoyageChildren' + index,attrs:{"value":childrenOption}},[_vm._v(" "+_vm._s(childrenOption.text)+" "),(
                                      childrenOption.code &&
                                      _vm.form.motifVoyage.radio &&
                                      _vm.form.motifVoyage.radio.code ===
                                        childrenOption.code
                                    )?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.motifVoyage.input),callback:function ($$v) {_vm.$set(_vm.form.motifVoyage, "input", $$v)},expression:"form.motifVoyage.input"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1)}),1):_vm._e()],1)}),1),_c('b-row',{staticClass:"mt-1"},_vm._l((_vm.listTypeVoyage),function(typeVoyage,typeVoyageIndex){return _c('b-col',{key:'typeVoyage' + typeVoyageIndex,attrs:{"md":"6"}},[_c('p',[_c('strong',[_vm._v(_vm._s(typeVoyage.label))])]),_c('b-form-radio-group',{attrs:{"stacked":""},model:{value:(_vm.form.motifVoyage.radio),callback:function ($$v) {_vm.$set(_vm.form.motifVoyage, "radio", $$v)},expression:"form.motifVoyage.radio"}},_vm._l((typeVoyage.children),function(item,index){return _c('b-form-radio',{key:'typeVoyageItem' + index,attrs:{"name":"motifVoyage","value":item}},[_vm._v(" "+_vm._s(item.text)+" "),(
                                      item.code &&
                                      _vm.form.motifVoyage.radio &&
                                      _vm.form.motifVoyage.radio.code ===
                                        item.code
                                    )?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.motifVoyage.input),callback:function ($$v) {_vm.$set(_vm.form.motifVoyage, "input", $$v)},expression:"form.motifVoyage.input"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1)}),1)],1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"6. Parmi les activités suivantes, lesquelles avez-vous pratiquées ? (plusieurs réponses si possible)"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-checkbox-group',{attrs:{"name":'typeActivite',"stacked":""},model:{value:(_vm.form.activiteBenin),callback:function ($$v) {_vm.$set(_vm.form, "activiteBenin", $$v)},expression:"form.activiteBenin"}},[_c('b-row',_vm._l((_vm.listTypeActivites),function(item,index){return _c('b-col',{key:'typeActivite' + index,attrs:{"md":"6"}},[_c('b-form-checkbox',{attrs:{"id":'typeActivite' + item.value,"value":item}},[_vm._v(" "+_vm._s(item.text)+" "),(
                                      item.code &&
                                      _vm.form.activiteBenin.some(
                                        function (e) { return e.code === item.code; }
                                      )
                                    )?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.listTypeActivites[index].input),callback:function ($$v) {_vm.$set(_vm.listTypeActivites[index], "input", $$v)},expression:"listTypeActivites[index].input"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1)],1)}),1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"table-responsive text-left mt-3"},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label-size":"lg","label":"7. Indiquez le nombre de nuits passées par type d’hébergement pendant votre séjour à l’étranger","label-for":"motif_travel"}}),_c('b-form-checkbox',{staticClass:"mb-1",model:{value:(_vm.hideNombreNuitTable),callback:function ($$v) {_vm.hideNombreNuitTable=$$v},expression:"hideNombreNuitTable"}},[_vm._v("Je n’ai pas passé de nuit")]),(!_vm.hideNombreNuitTable)?_c('table',{staticClass:"table table-striped table-bordered"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Type d'hébergement ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Nuitées")])])]),_c('tbody',_vm._l((_vm.nombreNuitParType),function(item,index){return _c('tr',{key:index},[_c('th',{attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(item.type)+" "),(item.hasManualInput)?_c('validation-provider',{attrs:{"rules":_vm.nombreNuitParType[index].nombreNuit
                                      ? 'required'
                                      : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                      var errors = ref.errors;
return [_c('b-form-input',{staticClass:"d-inline-block",model:{value:(_vm.nombreNuitParType[index].input),callback:function ($$v) {_vm.$set(_vm.nombreNuitParType[index], "input", $$v)},expression:"nombreNuitParType[index].input"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1),_c('td',[_c('b-form-input',{attrs:{"type":"number","min":"0"},model:{value:(
                                    _vm.nombreNuitParType[index].nombreNuit
                                  ),callback:function ($$v) {_vm.$set(_vm.nombreNuitParType[index], "nombreNuit", $$v)},expression:"\n                                    nombreNuitParType[index].nombreNuit\n                                  "}})],1)])}),0)]):_vm._e()],1)]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"8. Par quel principal moyen de transport êtes-vous arrivé au Bénin ?","label-for":"moyen_transport"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"stacked":""},model:{value:(_vm.form.moyenTransportArriveeBenin.radio),callback:function ($$v) {_vm.$set(_vm.form.moyenTransportArriveeBenin, "radio", $$v)},expression:"form.moyenTransportArriveeBenin.radio"}},_vm._l((_vm.moyen_transport),function(item,index){return _c('b-form-radio',{key:'moyen_transport' + index,attrs:{"value":item}},[_vm._v(" "+_vm._s(item.text)+" "),(
                                  item.code &&
                                  _vm.form.moyenTransportArriveeBenin.radio &&
                                  _vm.form.moyenTransportArriveeBenin.radio
                                    .code === item.code
                                )?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{model:{value:(
                                    _vm.form.moyenTransportArriveeBenin.input
                                  ),callback:function ($$v) {_vm.$set(_vm.form.moyenTransportArriveeBenin, "input", $$v)},expression:"\n                                    form.moyenTransportArriveeBenin.input\n                                  "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(
                        _vm.form.moyenTransportArriveeBenin.radio &&
                        _vm.form.moyenTransportArriveeBenin.radio.value ===
                          'Avion'
                      )?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"9. Si vous êtes arrivé par avion, veuillez préciser s’il s’agit :","label-for":"si_avion"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"value-field":"id","text-field":"name","options":_vm.si_avion,"name":"radio-validation"},model:{value:(_vm.form.arriveeBeninParAvion.type),callback:function ($$v) {_vm.$set(_vm.form.arriveeBeninParAvion, "type", $$v)},expression:"form.arriveeBeninParAvion.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1649736585)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mt-2",attrs:{"placeholder":"Veuillez préciser la compagnie aérienne","type":"text"},model:{value:(_vm.form.arriveeBeninParAvion.compagnie),callback:function ($$v) {_vm.$set(_vm.form.arriveeBeninParAvion, "compagnie", $$v)},expression:"form.arriveeBeninParAvion.compagnie"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3709995458)})],1)],1):_vm._e(),(
                        _vm.form.moyenTransportArriveeBenin.radio &&
                        _vm.form.moyenTransportArriveeBenin.radio.value ===
                          'Autobus'
                      )?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"10. Si vous êtes arrivé en autobus, veuillez préciser :","label-for":"si_autobus"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"Le nom de la compagnie de transport","label-for":"si_autobus"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mt-2",attrs:{"placeholder":"Veuillez préciser la compagnie de transport","type":"text"},model:{value:(_vm.form.arriveeBeninParAutobus.compagnie),callback:function ($$v) {_vm.$set(_vm.form.arriveeBeninParAutobus, "compagnie", $$v)},expression:"form.arriveeBeninParAutobus.compagnie"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2518142055)})],1),_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"La résidence de la compagnie de transport (choisir une seule réponse)","label-for":"si_autobus"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"value-field":"id","text-field":"name","options":_vm.si_autobus,"name":"radio-validation"},model:{value:(
                                _vm.form.arriveeBeninParAutobus.residenceCompagnie
                              ),callback:function ($$v) {_vm.$set(_vm.form.arriveeBeninParAutobus, "residenceCompagnie", $$v)},expression:"\n                                form.arriveeBeninParAutobus.residenceCompagnie\n                              "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,523332448)})],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"11. Quels moyens de transport avez-vous utilisé au Bénin ?","label-for":"moyen_transport_utilise"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"stacked":""},model:{value:(_vm.form.moyenTransportBenin.radio),callback:function ($$v) {_vm.$set(_vm.form.moyenTransportBenin, "radio", $$v)},expression:"form.moyenTransportBenin.radio"}},_vm._l((_vm.moyen_transport_utilise_benin),function(item,index){return _c('b-form-radio',{key:'moyen_transport_utilise_benin' + index,attrs:{"value":item}},[_vm._v(" "+_vm._s(item.text)+" "),(
                                  item.code &&
                                  _vm.form.moyenTransportBenin.radio &&
                                  _vm.form.moyenTransportBenin.radio.code ===
                                    item.code
                                )?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.moyenTransportBenin.input),callback:function ($$v) {_vm.$set(_vm.form.moyenTransportBenin, "input", $$v)},expression:"form.moyenTransportBenin.input"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"12. Indiquez combien vous avez payé en tout pour ce voyage avant votre arrivée au Bénin (par exemple voyage à forfait = transport + hébergement, etc.)","label-for":"cout_avant_benin"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"stacked":""},model:{value:(_vm.depensesAvantArrivee.type),callback:function ($$v) {_vm.$set(_vm.depensesAvantArrivee, "type", $$v)},expression:"depensesAvantArrivee.type"}},_vm._l((_vm.cout_avant_benin),function(item,index){return _c('b-form-radio',{key:'cout_avant_benin' + index,attrs:{"name":"cout_avant_benin","value":item}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(
                          _vm.depensesAvantArrivee.type &&
                          _vm.depensesAvantArrivee.type.code !== 'aucun_paiement'
                        )?_c('div',{staticClass:"table-responsive text-left mt-3"},[_c('table',{staticClass:"table table-striped table-bordered"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Cochez")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Voyage à forfait: cochez ce qui était inclus et indiquez le cout total ")])])]),_c('tbody',[_vm._l((_vm.depensesAvantArrivee.options),function(item,index){return _c('tr',{key:index},[_c('td',[_c('b-form-checkbox',{model:{value:(
                                    _vm.depensesAvantArrivee.options[index]
                                      .selected
                                  ),callback:function ($$v) {_vm.$set(_vm.depensesAvantArrivee.options[index]
                                      , "selected", $$v)},expression:"\n                                    depensesAvantArrivee.options[index]\n                                      .selected\n                                  "}})],1),_c('td',{attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(item.title)+" "),(
                                    _vm.depensesAvantArrivee.options[index]
                                      .selected &&
                                    _vm.depensesAvantArrivee.type.code ===
                                      'sans_forfait'
                                  )?_c('b-form-input',{attrs:{"type":"number","min":"0"},model:{value:(
                                    _vm.depensesAvantArrivee.options[index].value
                                  ),callback:function ($$v) {_vm.$set(_vm.depensesAvantArrivee.options[index], "value", $$v)},expression:"\n                                    depensesAvantArrivee.options[index].value\n                                  "}}):_vm._e()],1)])}),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("Coût total")]),_c('td',[_c('b-form-input',{attrs:{"type":"number","min":"0","value":_vm.depensesAvantArrivee.coutTotal}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Indiquez la devise dans laquelle vous avez payé ")]),_c('td',[_c('b-form-input',{attrs:{"type":"text"}})],1)])],2)])]):_vm._e()],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"13. Indiquez combien vous avez payé en tout pour ce voyage pendant votre séjour au Bénin.","label-for":"cout_etranger"}},[_c('b-form-checkbox',{staticClass:"mb-1",model:{value:(_vm.hideDepensesDurantSejour),callback:function ($$v) {_vm.hideDepensesDurantSejour=$$v},expression:"hideDepensesDurantSejour"}},[_vm._v("Je n’ai rien payé pendant mon séjour")])],1),(!_vm.hideDepensesDurantSejour)?_c('div',{staticClass:"table-responsive text-left mt-3"},[_c('table',{staticClass:"table table-striped table-bordered"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Indiquez le montant sur les lignes correspondantes ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Indiquez la devise dans laquelle vous avez payé ")])])]),_c('tbody',[_vm._l((_vm.depensesDurantSejour.options),function(item,index){return _c('tr',{key:index},[_c('th',{attrs:{"scope":"row"}},[_c('span',[_c('span',{attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(item.label)+" "),_c('b-form-input',{attrs:{"type":"number","min":"0"},model:{value:(
                                        _vm.depensesDurantSejour.options[index]
                                          .value
                                      ),callback:function ($$v) {_vm.$set(_vm.depensesDurantSejour.options[index]
                                          , "value", $$v)},expression:"\n                                        depensesDurantSejour.options[index]\n                                          .value\n                                      "}})],1)])]),_c('th',[_c('v-select',{attrs:{"type":"text","options":_vm.devise,"label":"title","placeholder":"F CFA"},model:{value:(
                                    _vm.depensesDurantSejour.options[index].devise
                                  ),callback:function ($$v) {_vm.$set(_vm.depensesDurantSejour.options[index], "devise", $$v)},expression:"\n                                    depensesDurantSejour.options[index].devise\n                                  "}})],1)])}),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("Coût total")]),_c('td',[_c('b-form-input',{attrs:{"type":"number","min":"0","value":_vm.depensesDurantSejour.coutTotal}})],1)])],2)])]):_vm._e()],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"14. Dans l’ensemble, votre séjour au Bénin vous a-t-il satisfait ? (Choisissez une seule réponse)","label-for":"sejour_satisfaisant"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.sejour_satisfaisant,"value-field":"id","text-field":"name","stacked":""},model:{value:(_vm.form.satisfactionSejour),callback:function ($$v) {_vm.$set(_vm.form, "satisfactionSejour", $$v)},expression:"form.satisfactionSejour"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"15. Venez-vous souvent au Bénin ?","label-for":"souvent_etranger"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.souvent_etranger,"value-field":"id","text-field":"name","stacked":""},model:{value:(_vm.form.frequenceVoyage),callback:function ($$v) {_vm.$set(_vm.form, "frequenceVoyage", $$v)},expression:"form.frequenceVoyage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"16. Sexe","label-for":"sexe"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.sexe,"name":"radio-sexe"},model:{value:(_vm.form.sexe),callback:function ($$v) {_vm.$set(_vm.form, "sexe", $$v)},expression:"form.sexe"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label":"17. Votre âge","label-for":"age"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Age","type":"number","min":"0"},model:{value:(_vm.form.age),callback:function ($$v) {_vm.$set(_vm.form, "age", $$v)},expression:"form.age"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"col-xl-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){return _vm.sendToPreview()}}},[_vm._v(" Aperçu avant enregistrement ")])],1)])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }