  <template>
  <div>
    <validation-observer ref="formRules">
      <!-- head -->
      <div>
        <!-- <b-row> {{form}}</b-row> -->
        <b-card>
          <b-card-header>
            <b-row no-gutters class="w-100">
              <b-col md="12">
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button
                      size="lg"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      title="Informations"
                      v-b-popover.hover.focus="
                        '⚪️ La fiche d\'enquête sur les visiteurs internationaux - frontieres (tourisme récepteur) est éditable par le grand public et les agents annexes. ⚪️ Il permet de collecter les données sur les voyageurs et leur séjours. ⚪️ Le remplissage de la fiche ne nécessite pas une connexion ou la création d\'un compte.'
                      "
                    >
                      <feather-icon icon="BellIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
              <b-col md="2">
                <b-card-img
                  :src="
                    require('@/assets/images/illustration/Globalization-pana.png')
                  "
                  alt="Image"
                  class="rounded-0 my-2"
                >
                </b-card-img>
              </b-col>
              <b-col
                md="10"
                class="d-flex align-items-center justify-content-center"
              >
                <div>
                  <!-- <h1 class="m-auto text-center">FICHE STATISTIQUE</h1> -->
                  <br />
                  <!-- <h4 class="m-auto text-center uppercase">
                    Enquête sur les visiteurs internationaux – frontières
                    (Tourisme récepteur)
                  </h4> -->
                  <!-- <h1 class="m-auto text-center">FICHE D'ENQUETE SUR LES VISITEURS INTERNATIONAUX - FRONTIERES</h1>
                  <h1 class="m-auto text-center"> TOURISME RÉCEPTEUR</h1> -->
                </div>
                <div>
                  <h1 class="m-auto text-center">
                    FICHE D'ENQUETE SUR LES VISITEURS INTERNATIONAUX -
                    FRONTIERES
                  </h1>
                  <h1 class="m-auto text-center">TOURISME RÉCEPTEUR</h1>
                  <!-- <br /> -->
                  <!-- <h4 class="m-auto text-center">
                    Location de véhicules sans opérateur
                  </h4> -->
                  <!-- <h4 class="m-auto text-center uppercase">
                    Enquête sur les visiteurs internationaux – frontières
                    (Tourisme récepteur)
                  </h4> -->
                </div>
              </b-col>
            </b-row>
          </b-card-header>
          <b-row>
            <!-- body -->
            <b-col lg="12" class="pb-2">
              <div>
                <b-card>
                  <b-card-body>
                    <b-row>
                      <!-- pays residence -->
                      <b-col md="12">
                        <b-form-group
                          label-cols-lg="12"
                          label="1. Dans quel pays vivez-vous ?"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <v-select
                              v-model="form.paysResidence"
                              type="text"
                              :options="pays"
                              label="name"
                              :reduce="(option) => option.name"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Nationalite -->
                      <b-col md="12">
                        <b-form-group
                          label-cols-lg="12"
                          label="2. De quelle nationalité êtes-vous ?"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <v-select
                              v-model="form.nationalite"
                              type="text"
                              :options="pays"
                              label="name"
                              :reduce="(option) => option.name"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Date -->
                      <b-col md="12">
                        <b-form-group
                          label-cols-md="12"
                          label="3. A quelle date êtes-vous arrivés au Bénin ?"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <flat-pickr
                              v-model="form.dateArriveeBenin"
                              class="form-control"
                              placeholder="Date"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Nombre de personne -->
                      <b-col md="12">
                        <b-form-group
                          label-cols-lg="12"
                          label="4. Avec combien de personnes, y compris vous-même, voyagez-vous en partageant les dépenses ?"
                        >
                          <div class="d-flex align-items-center gap-10">
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              class="d-inline-block"
                            >
                              <b-form-input
                                v-model.number="form.coVoyageursAdultes"
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <div class="d-inline-block">personnes dont</div>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              class="d-inline-block"
                            >
                              <b-form-input
                                v-model.number="form.coVoyageursEnfants"
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <div>enfants de moins de 18 ans.</div>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- motif visite -->
                      <b-col md="12">
                        <b-form-group
                          label-cols-lg="12"
                          label="5. Quel a été le motif de votre visite au Bénin ? (Choisissez une seule réponse)"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-radio-group
                              v-model="form.motifVoyage.radio"
                              stacked
                            >
                              <b-form-radio
                                v-for="(motif, motifIndex) in motifVoyage"
                                :key="'motifVoyage' + motifIndex"
                                :value="motif"
                                name="motifVoyage"
                              >
                                {{ motif.text }}

                                <b-form-radio-group
                                  stacked
                                  v-if="motif.children"
                                  name="motifVoyage"
                                  v-model="form.motifVoyage.radio"
                                >
                                  <b-form-radio
                                    v-for="(
                                      childrenOption, index
                                    ) in motif.children"
                                    :key="'motifVoyageChildren' + index"
                                    :value="childrenOption"
                                  >
                                    {{ childrenOption.text }}
                                    <validation-provider
                                      v-if="
                                        childrenOption.code &&
                                        form.motifVoyage.radio &&
                                        form.motifVoyage.radio.code ===
                                          childrenOption.code
                                      "
                                      #default="{ errors }"
                                      rules="required"
                                    >
                                      <b-form-input
                                        v-model="form.motifVoyage.input"
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-radio>
                                </b-form-radio-group>
                              </b-form-radio>
                            </b-form-radio-group>
                            <!-- type de voyage -->
                            <b-row class="mt-1">
                              <b-col
                                md="6"
                                v-for="(
                                  typeVoyage, typeVoyageIndex
                                ) in listTypeVoyage"
                                :key="'typeVoyage' + typeVoyageIndex"
                              >
                                <p>
                                  <strong>{{ typeVoyage.label }}</strong>
                                </p>

                                <b-form-radio-group
                                  stacked
                                  v-model="form.motifVoyage.radio"
                                >
                                  <b-form-radio
                                    name="motifVoyage"
                                    v-for="(item, index) in typeVoyage.children"
                                    :key="'typeVoyageItem' + index"
                                    :value="item"
                                  >
                                    {{ item.text }}
                                    <validation-provider
                                      v-if="
                                        item.code &&
                                        form.motifVoyage.radio &&
                                        form.motifVoyage.radio.code ===
                                          item.code
                                      "
                                      #default="{ errors }"
                                      rules="required"
                                    >
                                      <b-form-input
                                        v-model="form.motifVoyage.input"
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-radio>
                                </b-form-radio-group>
                              </b-col>
                            </b-row>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- activite prtique -->
                      <b-col md="12">
                        <b-form-group
                          label-cols-lg="12"
                          label="6. Parmi les activités suivantes, lesquelles avez-vous pratiquées ? (plusieurs réponses si possible)"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <!-- type de voyage -->
                            <b-form-checkbox-group
                              :name="'typeActivite'"
                              stacked
                              v-model="form.activiteBenin"
                            >
                              <b-row>
                                <b-col
                                  v-for="(item, index) in listTypeActivites"
                                  :key="'typeActivite' + index"
                                  md="6"
                                >
                                  <b-form-checkbox
                                    :id="'typeActivite' + item.value"
                                    :value="item"
                                  >
                                    {{ item.text }}
                                    <validation-provider
                                      v-if="
                                        item.code &&
                                        form.activiteBenin.some(
                                          (e) => e.code === item.code
                                        )
                                      "
                                      #default="{ errors }"
                                      rules="required"
                                    >
                                      <b-form-input
                                        v-model="listTypeActivites[index].input"
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-checkbox>
                                </b-col>
                              </b-row>
                            </b-form-checkbox-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <!-- Nuits passées -->
                        <div class="table-responsive text-left mt-3">
                          <b-form-group
                            label-cols-lg="12"
                            label-size="lg"
                            label="7. Indiquez le nombre de nuits passées par type d’hébergement pendant votre séjour à l’étranger"
                            label-for="motif_travel"
                          />
                          <b-form-checkbox
                            v-model="hideNombreNuitTable"
                            class="mb-1"
                            >Je n’ai pas passé de nuit</b-form-checkbox
                          >
                          <table
                            class="table table-striped table-bordered"
                            v-if="!hideNombreNuitTable"
                          >
                            <thead>
                              <tr>
                                <th scope="col" class="text-left">
                                  Type d'hébergement
                                </th>
                                <th scope="col" class="text-left">Nuitées</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(item, index) in nombreNuitParType"
                                :key="index"
                              >
                                <th scope="row">
                                  {{ item.type }}
                                  <validation-provider
                                    v-if="item.hasManualInput"
                                    #default="{ errors }"
                                    :rules="
                                      nombreNuitParType[index].nombreNuit
                                        ? 'required'
                                        : ''
                                    "
                                  >
                                    <b-form-input
                                      class="d-inline-block"
                                      v-model="nombreNuitParType[index].input"
                                    ></b-form-input>
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </th>
                                <td>
                                  <b-form-input
                                    type="number"
                                    min="0"
                                    v-model="
                                      nombreNuitParType[index].nombreNuit
                                    "
                                  ></b-form-input>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-col>
                      <!-- moyen de transport pour arriver au bénin -->
                      <b-col md="12">
                        <!-- Moyen transport pour arriver bénin -->
                        <b-form-group
                          label-cols-lg="12"
                          label="8. Par quel principal moyen de transport êtes-vous arrivé au Bénin ?"
                          label-for="moyen_transport"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-radio-group
                              v-model="form.moyenTransportArriveeBenin.radio"
                              stacked
                            >
                              <b-form-radio
                                v-for="(item, index) in moyen_transport"
                                :key="'moyen_transport' + index"
                                :value="item"
                              >
                                {{ item.text }}
                                <validation-provider
                                  v-if="
                                    item.code &&
                                    form.moyenTransportArriveeBenin.radio &&
                                    form.moyenTransportArriveeBenin.radio
                                      .code === item.code
                                  "
                                  #default="{ errors }"
                                  rules="required"
                                >
                                  <b-form-input
                                    v-model="
                                      form.moyenTransportArriveeBenin.input
                                    "
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-radio></b-form-radio-group
                            >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="12"
                        v-if="
                          form.moyenTransportArriveeBenin.radio &&
                          form.moyenTransportArriveeBenin.radio.value ===
                            'Avion'
                        "
                      >
                        <b-form-group
                          label-cols-lg="12"
                          label="9. Si vous êtes arrivé par avion, veuillez préciser s’il s’agit :"
                          label-for="si_avion"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-radio-group
                              v-model="form.arriveeBeninParAvion.type"
                              value-field="id"
                              text-field="name"
                              :options="si_avion"
                              class="demo-inline-spacing"
                              name="radio-validation"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model="form.arriveeBeninParAvion.compagnie"
                              placeholder="Veuillez préciser la compagnie aérienne"
                              class="mt-2"
                              type="text"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="12"
                        v-if="
                          form.moyenTransportArriveeBenin.radio &&
                          form.moyenTransportArriveeBenin.radio.value ===
                            'Autobus'
                        "
                      >
                        <b-form-group
                          label-cols-lg="12"
                          label="10. Si vous êtes arrivé en autobus, veuillez préciser :"
                          label-for="si_autobus"
                        >
                          <b-form-group
                            label-cols-lg="12"
                            label="Le nom de la compagnie de transport"
                            label-for="si_autobus"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model="form.arriveeBeninParAutobus.compagnie"
                                placeholder="Veuillez préciser la compagnie de transport"
                                class="mt-2"
                                type="text"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                          <b-form-group
                            label-cols-lg="12"
                            label="La résidence de la compagnie de transport (choisir une seule réponse)"
                            label-for="si_autobus"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-radio-group
                                v-model="
                                  form.arriveeBeninParAutobus.residenceCompagnie
                                "
                                value-field="id"
                                text-field="name"
                                :options="si_autobus"
                                class="demo-inline-spacing"
                                name="radio-validation"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-form-group>
                      </b-col>
                      <!-- Moyen transport utilisé a l'étranger -->
                      <b-col md="12">
                        <b-form-group
                          label-cols-lg="12"
                          label="11. Quels moyens de transport avez-vous utilisé au Bénin ?"
                          label-for="moyen_transport_utilise"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-radio-group
                              v-model="form.moyenTransportBenin.radio"
                              stacked
                            >
                              <b-form-radio
                                v-for="(
                                  item, index
                                ) in moyen_transport_utilise_benin"
                                :key="'moyen_transport_utilise_benin' + index"
                                :value="item"
                              >
                                {{ item.text }}
                                <validation-provider
                                  v-if="
                                    item.code &&
                                    form.moyenTransportBenin.radio &&
                                    form.moyenTransportBenin.radio.code ===
                                      item.code
                                  "
                                  #default="{ errors }"
                                  rules="required"
                                >
                                  <b-form-input
                                    v-model="form.moyenTransportBenin.input"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-radio>
                            </b-form-radio-group>
                            <!-- class="demo-inline-spacing control-label col-sm-3" -->
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- depenses avant arrivee benin -->
                      <b-col md="12">
                        <b-form-group
                          label-cols-lg="12"
                          label="12. Indiquez combien vous avez payé en tout pour ce voyage avant votre arrivée au Bénin (par exemple voyage à forfait = transport + hébergement, etc.)"
                          label-for="cout_avant_benin"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-radio-group
                              v-model="depensesAvantArrivee.type"
                              class="demo-inline-spacing"
                              stacked
                            >
                              <b-form-radio
                                name="cout_avant_benin"
                                v-for="(item, index) in cout_avant_benin"
                                :key="'cout_avant_benin' + index"
                                :value="item"
                              >
                                {{ item.name }}
                              </b-form-radio>
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <!-- Nuits passées -->
                        <div
                          class="table-responsive text-left mt-3"
                          v-if="
                            depensesAvantArrivee.type &&
                            depensesAvantArrivee.type.code !== 'aucun_paiement'
                          "
                        >
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th scope="col" class="text-left">Cochez</th>
                                <th scope="col" class="text-left">
                                  Voyage à forfait: cochez ce qui était inclus
                                  et indiquez le cout total
                                </th>
                                <!-- <th scope="col">Femmes</th> -->
                                <!-- <th scope="col">Total</th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(
                                  item, index
                                ) in depensesAvantArrivee.options"
                                :key="index"
                              >
                                <td>
                                  <b-form-checkbox
                                    v-model="
                                      depensesAvantArrivee.options[index]
                                        .selected
                                    "
                                  ></b-form-checkbox>
                                </td>
                                <td scope="row">
                                  {{ item.title }}
                                  <b-form-input
                                    type="number"
                                    min="0"
                                    v-model="
                                      depensesAvantArrivee.options[index].value
                                    "
                                    v-if="
                                      depensesAvantArrivee.options[index]
                                        .selected &&
                                      depensesAvantArrivee.type.code ===
                                        'sans_forfait'
                                    "
                                  ></b-form-input>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Coût total</th>
                                <td>
                                  <b-form-input
                                    type="number"
                                    min="0"
                                    :value="depensesAvantArrivee.coutTotal"
                                  ></b-form-input>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  Indiquez la devise dans laquelle vous avez
                                  payé
                                </th>
                                <td>
                                  <b-form-input type="text"></b-form-input>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-col>
                      <!-- depenses pendant sejour benin -->
                      <b-col md="12">
                        <b-form-group
                          label-cols-lg="12"
                          label="13. Indiquez combien vous avez payé en tout pour ce voyage pendant votre séjour au Bénin."
                          label-for="cout_etranger"
                        >
                          <b-form-checkbox
                            v-model="hideDepensesDurantSejour"
                            class="mb-1"
                            >Je n’ai rien payé pendant mon
                            séjour</b-form-checkbox
                          >
                        </b-form-group>
                        <!-- tableau pendant sejour etranger  -->
                        <div
                          class="table-responsive text-left mt-3"
                          v-if="!hideDepensesDurantSejour"
                        >
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th scope="col" class="text-left">
                                  Indiquez le montant sur les lignes
                                  correspondantes
                                </th>
                                <th scope="col" class="text-left">
                                  Indiquez la devise dans laquelle vous avez
                                  payé
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(
                                  item, index
                                ) in depensesDurantSejour.options"
                                :key="index"
                              >
                                <th scope="row">
                                  <span>
                                    <span scope="row">
                                      {{ item.label }}
                                      <b-form-input
                                        type="number"
                                        min="0"
                                        v-model="
                                          depensesDurantSejour.options[index]
                                            .value
                                        "
                                      ></b-form-input>
                                    </span>
                                  </span>
                                </th>
                                <th>
                                  <v-select
                                    v-model="
                                      depensesDurantSejour.options[index].devise
                                    "
                                    type="text"
                                    :options="devise"
                                    label="title"
                                    placeholder="F CFA"
                                  />
                                </th>
                              </tr>

                              <tr>
                                <th scope="row">Coût total</th>
                                <td>
                                  <b-form-input
                                    type="number"
                                    min="0"
                                    :value="depensesDurantSejour.coutTotal"
                                  ></b-form-input>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-col>
                      <!-- statisfaction -->
                      <b-col md="12">
                        <b-form-group
                          label-cols-lg="12"
                          label="14. Dans l’ensemble, votre séjour au Bénin vous a-t-il satisfait ? (Choisissez une seule réponse)"
                          label-for="sejour_satisfaisant"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-radio-group
                              v-model="form.satisfactionSejour"
                              :options="sejour_satisfaisant"
                              value-field="id"
                              text-field="name"
                              stacked
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- fréquence visite -->
                      <b-col md="12">
                        <b-form-group
                          label-cols-lg="12"
                          label="15. Venez-vous souvent au Bénin ?"
                          label-for="souvent_etranger"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-radio-group
                              v-model="form.frequenceVoyage"
                              :options="souvent_etranger"
                              value-field="id"
                              text-field="name"
                              stacked
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- sexe -->
                      <b-col md="12">
                        <b-form-group
                          label-cols-lg="12"
                          label="16. Sexe"
                          label-for="sexe"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-radio-group
                              v-model="form.sexe"
                              :options="sexe"
                              class="demo-inline-spacing"
                              name="radio-sexe"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- age -->
                      <b-col md="12">
                        <b-form-group
                          label-cols-lg="12"
                          label="17. Votre âge"
                          label-for="age"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model="form.age"
                              placeholder="Age"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>
              </div>
              <div class="text-right">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="col-xl-2"
                  @click="sendToPreview()"
                >
                  Aperçu avant enregistrement
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </validation-observer>
    <!-- <tips-modal-create-from-last-record :canShow="showCreateFromLastModal"></tips-modal-create-from-last-record> -->
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardImg,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormRadioGroup,
  BFormRadio,
  // BForm,
  // BCardText,
  BInputGroupPrepend,
  BInputGroup,
  VBPopover,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ContentLoader } from "vue-content-loader";
import localstorageService from "@/services/localstorage/localstorage.service";
import TipsModalCreateFromLastRecord from "@/components/TipsModalCreateFromLastRecord.vue";
import utilsService from "@/services/utils/utils.service";
import flatPickr from "vue-flatpickr-component";

// imports for validation
// const store = require('store')
const listVoyagePrive = [
  {
    text: "Visite de parents/d’amis",
    value: "Visite de parentsvaluemis",
  },
  {
    text: "Vacances, loisirs et détente",
    value: "Vacances, loisirs et détente",
  },
  {
    text: "Éducation et formation ",
    value: "Éducation et formation ",
  },
  {
    text: "Santé et soins médicaux",
    value: "Santé et soins médicaux",
  },
  {
    text: "Religion/pèlerinage",
    value: "Religion/pèlerinage",
  },
  {
    text: "Achat ",
    value: "Achat ",
  },
  {
    text: "Transit ",
    value: "Transit ",
  },
  {
    text: "Autres",
    value: "Autres",
    code: "autres_voyage_prive",
    hasManualInput: true,
  },
];
const listVoyageAffaire = [
  {
    text: "Voyage d’affaire individuel (par exemple, visite d’un fournisseur ou d’un client)",
    value:
      "Voyage d’affaire individuel (par exemple, visite d’un fournisseur ou d’un client)",
  },
  {
    text: "Conférence/congrès/séminaire",
    value: "Conférence/congrès/séminaire",
  },
  {
    text: "Assemblée de la société/Réunion d’affaire",
    value: "Assemblée de la société/Réunion d’affaire",
  },
  {
    text: "Foire ou autre manifestation commerciale",
    value: "Foire ou autre manifestation commerciale",
  },
  {
    text: "Voyage de motivation (le voyage est payépar votre société/organisation)",
    value:
      "Voyage de motivation (le voyage est payépar votre société/organisation)",
  },
  {
    text: "Autres",
    value: "Autres",
    code: "autres_voyage_affaire",
    hasManualInput: true,
  },
];
const listTypeActivites = [
  {
    text: "Restaurant/bars/cafés/snack-bars ",
    value: "Restaurant/bars/cafés/snack-bars ",
  },
  {
    text: "Achats ",
    value: "Achats ",
  },
  {
    text: "Concerts/festival de musique ",
    value: "Concerts/festival de musique ",
  },
  {
    text: "Bâtiments/châteaux/monuments",
    value: "Bâtiments/châteaux/monuments",
  },
  {
    text: "Excursions ",
    value: "Excursions ",
  },
  {
    text: "Participation à une manifestation culturelle ",
    value: "Participation à une manifestation culturelle ",
  },
  {
    text: "Randonnée/trekking en forêt/montagne ",
    value: "Randonnée/trekking en forêt/montagne ",
  },
  {
    text: "Visite des parcs nationaux ",
    value: "Visite des parcs nationaux ",
  },
  {
    text: "Golf ",
    value: "Golf ",
  },
  {
    text: "Chasse",
    value: "Chasse",
  },
  {
    text: "Manifestation sportive",
    value: "Manifestation sportive",
  },
  {
    text: "Théâtre",
    value: "Théâtre",
  },
  {
    text: "Evènement familial (mariage, funérailles,baptême, première communion, etc.)",
    value:
      "Evènement familial (mariage, funérailles,baptême, première communion, etc.)",
  },
  {
    text: "Musées",
    value: "Musées",
  },
  {
    text: "Zoo/parcs d’attraction",
    value: "Zoo/parcs d’attraction",
  },
  {
    text: "Plage",
    value: "Plage",
  },
  {
    text: "Formation/conférence/séminaire",
    value: "Formation/conférence/séminaire",
  },
  {
    text: "Visite de sociétés",
    value: "Visite de sociétés",
  },
  {
    text: "Autres. Veuillez préciser",
    value: "Autres. Veuillez préciser",
    code: "autres_activites_pratiques",
    hasManualInput: true,
  },
];

const validOutput = {
  paysResidence: "Albania",
  nationalite: "Anguilla",
  dateArriveeBenin: "2022-09-16",
  coVoyageursAdultes: "12",
  coVoyageursEnfants: "8",
  motifVoyage: "Santé et soins médicaux",
  activiteBenin: [
    "Concerts/festival de musique ",
    "Musées",
    "Formation/conférence/séminaire",
  ],
  nombreNuitParType: [
    {
      type: "Hôtel/Pension",
      nombreNuit: "23",
    },
    {
      type: "Parents/Amis",
      nombreNuit: null,
    },
    {
      type: "Auberge de jeunesse",
      nombreNuit: null,
    },
    {
      type: "Résidence secondaire",
      nombreNuit: null,
    },
    {
      type: "Maison/Appartement loué",
      nombreNuit: null,
    },
    {
      type: "Chambre d’hôte",
      nombreNuit: null,
    },
    {
      type: "Autre type d'hébergement. Veuillez préciser",
      nombreNuit: null,
      hasManualInput: true,
      input: "",
    },
  ],
  moyenTransportArriveeBenin: "Bâteau",
  arriveeBeninParAvion: {
    type: null,
    compagnie: null,
  },
  arriveeBeninParAutobus: {
    compagnie: null,
    residenceCompagnie: null,
  },
  moyenTransportBenin: "Taxi",
  depensesAvantArrivee: {
    type: {
      id: "Voyage sans forfait",
      name: "Voyage sans forfait",
      code: "sans_forfait",
    },
    coutTotal: 0,
    devise: null,
    options: [],
  },
  depensesDurantSejour: {
    coutTotal: 23,
    options: [
      {
        label: "Transport international",
        devise: "yen",
        value: "3",
      },
      {
        label: "Hébergement",
        devise: "dollars $",
        value: "4",
      },
      {
        label: "Transport local (train, bus, taxi, etc.)",
        devise: "fcfa f",
        value: "5",
      },
      {
        label: "Location de voitur",
        devise: "",
      },
      {
        label: "Carburant automobile",
        devise: "",
      },
      {
        label: "Repas et boissons",
        devise: "",
      },
      {
        label: "Produits culture",
        devise: "",
      },
      {
        label: "Sport et produit de loisir",
        devise: "",
      },
      {
        label: "Bien et services liés à la santé",
        devise: "",
      },
      {
        label: "Souvenirs, cadeaux, etc",
        devise: "yen",
        value: "11",
      },
      {
        label: "Textiles, vêtements, chaussures et produits en cuire",
        devise: "",
      },
      {
        label: "Taba",
        devise: "",
      },
      {
        label: "Objets de value",
        devise: "",
      },
      {
        label: "Autre",
        devise: "",
      },
    ],
  },
  satisfactionSejour: "Très content",
  frequenceVoyage: "Tous les deux ans / tous les trois ans",
  sexe: "Masculin",
  age: "12",
  auteur: "",
};
// store modules and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import utilsStoreModule from "@/store/utils";
import formulaireStoreModule from "@/store/formulaire";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "utils", module: utilsStoreModule },
      { path: "formulaire", module: formulaireStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    flatPickr,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormRadioGroup,
    BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCardImg,
    // BCardText,
    // BForm,
    BInputGroupPrepend,
    BInputGroup,
    ContentLoader,
    TipsModalCreateFromLastRecord,
    VBPopover,
  },
  directives: {
    Ripple,
    "b-popover": VBPopover,
  },
  mixins: [heightTransition], //
  props: {
    etablissementDetails: null,
  },
  data() {
    return {
      listTypeActivites,
      isFillingForm: false,
      formInput: this.$route.params.data,
      lastRecordedForm: null,
      tipsModalRef: new Date().getTime(),
      showCreateFromLastModal: true,
      currentUserId: localstorageService.getUserId(),
      isCheckingForLastForm: true,
      formType: "formstatagence",
      email,
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      annees: [],
      mois: [],
      departements: [],
      communes: [],
      arrondissements: [],
      quartiers: [],
      pays: [],
      sexeList: [
        {
          text: "Masculin/Male",
          value: "Masculin/Male",
        },
        {
          text: "Féminin/Female",
          value: "Féminin/Female",
        },
      ],
      categorieSocioProList: [
        {
          text: "Libéral/indépendant",
          value: "Libéral/indépendant",
        },
        {
          text: "Employé/salarié",
          value: "Employé/salarié",
        },
        {
          text: "Retraité",
          value: "Retraité",
        },
        {
          text: "Autres (A préciser)",
          value: "Autres",
        },
      ],
      modesHebergement: [
        {
          text: "Hôtel et assimilés ",
          value: "Hôtel et assimilés ",
        },
        {
          text: "Famille/Amis ",
          value: "Famille/Amis",
        },
        {
          text: "Autres",
          value: "Autres",
        },
      ],
      listTypeVoyage: [
        {
          label: "Voyage privé",
          children: listVoyagePrive,
        },
        {
          label: "Voyage d’affaire",
          children: listVoyageAffaire,
        },
      ],
      motifVoyage: [
        {
          text: "Emploi dans une entité résidente dans ce pays.",
          value: "Emploi dans une entité résidente dans ce pays.",
          disabled: true,
          children: [
            {
              text: "Travailleur frontalier",
              value: "Travailleur frontalier",
            },
            {
              text: "Travailleur saisonnier",
              value: "Travailleur saisonnier",
            },
            {
              text: "Autres",
              value: "Autres",
              code: "autres_motif_emoloi",
              hasManualInput: true,
            },
          ],
          canStopFilling: true,
        },
        {
          text: "Diplomate, agent consulaire ou militaire en service.",
          value: "Diplomate, agent consulaire ou militaire en service.",
          canStopFilling: true,
        },
        {
          text: "Chauffeur professionnel.",
          value: "Chauffeur professionnel.",
          canStopFilling: true,
        },
        {
          text: "Equipage de navire/personnel aérien.",
          value: "Equipage de navire/personnel aérien.",
          canStopFilling: true,
        },
        {
          text: "En transit à l’aéroport (transfert, vol de continuation vers un autre pays).",
          value:
            "En transit à l’aéroport (transfert, vol de continuation vers un autre pays).",
          canStopFilling: true,
        },
      ],
      // Moyen_transport
      moyen_transport: [
        {
          text: "Avion",
          value: "Avion",
        },
        {
          text: "Voiture",
          value: "Voiture",
        },
        {
          text: "Autobus",
          value: "Autobus",
        },
        {
          text: "Bâteau",
          value: "Bâteau",
        },
        {
          text: "Barque / Pirogue",
          value: "Barque / Pirogue",
        },
        {
          text: "Motocyclette",
          value: "Motocyclette",
        },
        {
          text: "Bicyclette",
          value: "Bicyclette",
        },
        {
          text: "Autres",
          value: "Autres",
          code: "autres_moyen_transport",
          hasManualInput: true,
        },
      ],
      moyen_transport_utilise_benin: [
        {
          text: "Avion",
          value: "Avion",
        },
        {
          text: "Voiture personnelle",
          value: "Voiture personnelle",
        },
        {
          text: "Voiture de location",
          value: "Voiture de location",
        },
        {
          text: "Autobus",
          value: "Autobus",
        },
        {
          text: "Transport en commun",
          value: "Transport en commun",
        },
        {
          text: "Taxi",
          value: "Taxi",
        },
        {
          text: "Barque/pirogue",
          value: "Barque/pirogue",
        },
        {
          text: "Motocyclette",
          value: "Motocyclette",
        },
        {
          text: "Bicyclette",
          value: "Bicyclette",
        },
        {
          text: "Autre moyen de transport. Veuillez préciser",
          value: "Autres",
          code: "autres_moyen_transport_benin",
          hasManualInput: true,
        },
      ],

      si_avion: [
        {
          id: "Vol privé",
          name: "Vol privé",
        },
        {
          id: "Vol commercial",
          name: "Vol commercial",
        },
      ],

      si_autobus: [
        {
          id: "Compagnie béninoise",
          name: "Compagnie béninoise",
        },
        {
          id: "Compagnie étrangère",
          name: "Compagnie étrangère",
        },
      ],

      moyen_transport_utilise: [
        {
          id: "Avion",
          name: "Avion",
        },
        {
          id: "Voiture personnelle",
          name: "Voiture personnelle",
        },
        {
          id: "Voiture de location",
          name: "Voiture de location",
        },
        {
          id: "Autobus",
          name: "Autobus",
        },
        {
          id: "Transport en commun",
          name: "Transport en commun",
        },
        {
          id: "Taxi",
          name: "Taxi",
        },
        {
          id: "Barque / Pirogue",
          name: "Barque / Pirogue",
        },
        {
          id: "Motocyclette",
          name: "Motocyclette",
        },
        {
          id: "Bicyclette",
          name: "Bicyclette",
        },
        {
          id: "Autres moyen de transport.",
          name: "Autres moyen de transport. Veuillez préciser",
        },
      ],

      cout_avant_benin: [
        {
          id: "Je n'ai rien payé avant mon arrivé",
          name: "Je n'ai rien payé avant mon arrivé",
          code: "aucun_paiement",
        },
        {
          id: "Voyage à forfait",
          name: "Voyage à forfait",
          code: "forfait",
        },
        {
          id: "Voyage sans forfait",
          name: "Voyage sans forfait",
          code: "sans_forfait",
        },
      ],

      cout_etranger: [
        {
          id: "Je n'ai rien payé pendant mon séjour",
          name: "Je n'ai rien payé pendant mon séjour",
        },
        {
          id: "J'ai payé pendant mon séjour",
          name: "J'ai payé pendant mon séjour",
        },
      ],

      sejour_satisfaisant: [
        {
          id: "Très mécontent",
          name: "Très mécontent",
        },
        {
          id: "Mécontent",
          name: "Mécontent",
        },
        {
          id: "Ni mécontent, ni content",
          name: "Ni mécontent, ni content",
        },
        {
          id: "Content",
          name: "Content",
        },
        {
          id: "Très content",
          name: "Très content",
        },
      ],

      souvent_etranger: [
        {
          id: "Plusieurs fois par an",
          name: "Plusieurs fois par an",
        },
        {
          id: "Une fois par an",
          name: "Une fois par an",
        },
        {
          id: "Tous les deux ans / tous les trois ans",
          name: "Tous les deux ans / tous les trois ans",
        },
        {
          id: "Moins souvent",
          name: "Moins souvent",
        },
        {
          id: "C'est la premiere fois que je viens au Bénin",
          name: "C'est la premiere fois que je viens au Bénin",
        },
      ],

      devise: ["euro £", "dollars $", "fcfa f", "yen", "naira"],

      sexe: [
        {
          text: "Masculin",
          value: "Masculin",
        },
        {
          text: "Feminin",
          value: "Feminin",
        },
      ],

      hideDepensesDurantSejour: false,
      depensesDurantSejour: {
        coutTotal: null,
        options: [
          { label: "Transport international", devise: "" },
          { label: "Hébergement", devise: "" },
          { label: "Transport local (train, bus, taxi, etc.)", devise: "" },
          { label: "Location de voiture", devise: "" },
          { label: "Carburant automobile", devise: "" },
          { label: "Repas et boissons", devise: "" },
          { label: "Produits culturels", devise: "" },
          { label: "Sport et produit de loisirs", devise: "" },
          { label: "Bien et services liés à la santé", devise: "" },
          { label: "Souvenirs, cadeaux, etc", devise: "" },
          {
            label: "Textiles, vêtements, chaussures et produits en cuir",
            devise: "",
          },
          { label: "Taba", devise: "" },
          { label: "Objets de valeur", devise: "" },
          { label: "Autres", devise: "" },
        ],
      },
      agrementOptions: ["non", "oui"],
      autofillableFields: [],
      hideNombreNuitTable: false,
      nombreNuitParType: [
        {
          type: "Hôtel/pension",
          nombreNuit: null,
        },
        {
          type: "Parents/amis",
          nombreNuit: null,
        },
        {
          type: "Auberge de jeunesse",
          nombreNuit: null,
        },
        {
          type: "Résidence secondaire",
          nombreNuit: null,
        },
        {
          type: "Maison/appartement loué",
          nombreNuit: null,
        },
        {
          type: "Chambre d’hôte",
          nombreNuit: null,
        },
        {
          type: "Autre type d`hébergement. Veuillez préciser",
          nombreNuit: null,
          hasManualInput: true,
          input: "",
        },
      ],
      depensesAvantArrivee: {
        type: "",
        coutTotal: null,
        devise: null,
        options: [
          {
            title: "Transport international",
            value: null,
            selected: false,
          },
          {
            title: "Hébergement",
            value: null,
            selected: false,
          },
          {
            title: "Repas et boissons",
            value: null,
            selected: false,
          },
          {
            title: "Location de voiture",
            value: null,
            selected: false,
          },
          {
            title: "Transport local (train, bus, taxi, etc.)",
            value: null,
            selected: false,
          },
          {
            title: "Activité (loisir/sport/culture, etc)",
            value: null,
            selected: false,
          },
          {
            title: "Autre",
            value: null,
            selected: false,
          },
        ],
      },
      form: {
        paysResidence: null,
        nationalite: null,
        dateArriveeBenin: null,

        coVoyageursAdultes: null,
        coVoyageursEnfants: null,

        motifVoyage: { radio: null, input: "" },
        activiteBenin: [],
        nombreNuitParType: [
          {
            type: "Hôtel / pension",
            nombreNuit: null,
          },
          {
            type: "Parents/amis",
            nombreNuit: null,
          },
          {
            type: "Auberge de jeunesse",
            nombreNuit: null,
          },
          {
            type: "Résidence secondaire",
            nombreNuit: null,
          },
          {
            type: "Maison/appartement loué",
            nombreNuit: null,
          },
          {
            type: "Chambre d’hôte",
            nombreNuit: null,
          },
          {
            type: "Autres",
            code: "autres",
            hasManualInput: true,

            nombreNuit: null,
          },
        ],
        moyenTransportArriveeBenin: { radio: null, input: "" },
        arriveeBeninParAvion: {
          type: null,
          compagnie: null,
        },
        arriveeBeninParAutobus: {
          compagnie: null,
          residenceCompagnie: null,
        },
        moyenTransportBenin: { radio: null, input: "" },
        depensesAvantArrivee: {
          type: null,
          coutTotal: null,
          devise: null,
          options: [
            {
              title: "Transport international",
              value: null,
              etat: null,
            },
            {
              title: "Hébergement",
              value: null,
              etat: null,
            },
            {
              title: "Repas et boissons",
              value: null,
              etat: null,
            },
            {
              title: "Location de voiture",
              value: null,
              etat: null,
            },
            {
              title: "Transport local (train, bus, taxi, etc.)",
              value: null,
              etat: null,
            },
            {
              title: "Activité (loisir/sport/culture, etc)",
              value: null,
              etat: null,
            },
            {
              title: "Autre",
              value: null,
              etat: null,
            },
          ],
        },
        depensesDurantSejour: {
          type: null,
          coutTotal: null,
          devise: null,
          options: [
            {
              title: "Transport international",
              value: null,
              devise: null,
            },
            {
              title: "Hébergement",
              value: null,
              devise: null,
            },
            {
              title: "Transport local (train, bus, taxi, etc.)",
              value: null,
              devise: null,
            },
            {
              title: "Location de voiture",
              value: null,
              devise: null,
            },
            {
              title: "Carburant automibile",
              value: null,
              devise: null,
            },
            {
              title: "Repas et boissons",
              value: null,
              devise: null,
            },
            {
              title: "Produit culturel",
              value: null,
              devise: null,
            },
            {
              title: "Sport et produits de loisirs",
              value: null,
              devise: null,
            },
            {
              title: "Biens et services liés à la santé",
              value: null,
              devise: null,
            },
            {
              title: "Souvenis et cadeaux",
              value: null,
              devise: null,
            },
            {
              title: "Textiles, vêtements, chaussures et produits en cuir",
              value: null,
              devise: null,
            },
            {
              title: "Tabac",
              value: null,
              devise: null,
            },
            {
              title: "Objets de valeurs",
              value: null,
              devise: null,
            },
            {
              title: "Autre",
              value: null,
              etat: null,
            },
          ],
        },
        satisfactionSejour: null,
        frequenceVoyage: null,
        sexe: null,
        age: null,

        auteur: "",
      },

      validOutput: {},
      agrements: [
        {
          etablissement: "Les Hirondelles",
          reference: "hg7h878jhjKJ",
        },
      ],
      invalideAgrement: false,
      mauvaisEtablissement: false,
      invalideIfu: false,
    };
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer;
      if (rendererType === "sidebar-left")
        return "layout-content-renderer-left";
      if (rendererType === "sidebar-left-detached")
        return "layout-content-renderer-left-detached";
      return "layout-content-renderer-default";
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        // console.log("val", val, this.formInput);
        const userId = localstorageService.getUserId();
        const etablissementDetails = this.etablissementDetails || {};

        if (this.formInput) {
          this.isCheckingForLastForm = false;
          this.isFillingForm = true;
          this.form = {
            ...this.formInput,
          };
          setTimeout(() => {
            this.isFillingForm = false;
          }, 1000);
        } else {
          // this.form = {
          //   ...this.validOutput,
          // };
          setTimeout(() => {
            this.isCheckingForLastForm = false;
          }, 1000);
          this.form = {
            ...this.form,
          };
        }
      },
    },
    form: {
      deep: true,
      handler(val, old) {
        // console.log("form changes", val, this.form);
      },
    },
    // depensesDurantSejour
    "depensesDurantSejour.options": {
      deep: true,
      handler(val, old) {
        // console.log(val, coutTotal);
        const coutTotal = val.reduce((acc, curr) => {
          return acc + parseInt(curr.value || 0);
        }, 0);
        this.depensesDurantSejour.coutTotal = coutTotal;
      },
    },
    depensesDurantSejour: {
      deep: true,
      handler(val, old) {
        this.form.depensesDurantSejour = val;
      },
    },

    // depenses depensesAvantArrivee
    "depensesAvantArrivee.options": {
      deep: true,
      handler(val, old) {
        const coutTotal = val.reduce((acc, curr) => {
          return acc + parseInt(curr.value || 0);
        }, 0);
        this.depensesAvantArrivee.coutTotal = coutTotal;
      },
    },
    "depensesAvantArrivee.type": {
      deep: true,
      handler(val, old) {
        if (val && val.cde === "sans_forfait") {
          this.depensesAvantArrivee.options =
            this.depensesAvantArrivee.options.map((e) => {
              e.value = null;
              return e;
            });
        }
      },
    },
    depensesAvantArrivee: {
      deep: true,
      handler(val, old) {
        this.form.depensesAvantArrivee = val;
      },
    },

    //nombre de nuit par type ets
    hideNombreNuitTable: {
      deep: true,
      handler(val, old) {
        this.nombreNuitParType = this.nombreNuitParType.map((e) => {
          e.nombreNuit = null;
          return e;
        });
      },
    },
    nombreNuitParType: {
      deep: true,
      handler(val, old) {
        this.form.nombreNuitParType = val;
      },
    },
  },
  mounted() {
    // this.annees = utilsService.getListYears();
    // this.mois = utilsService.getListMonths();
    this.annees = null;
    this.mois = null;
    this.pays = utilsService.getListCountries();
    this.getListTypesEtablissement();
    this.loadDepartments();
    this.loadCommunes();
    this.loadArrondissements();
  },
  methods: {
    ...mapActions("params", {
      fetchAnnees: "fetchAnnees",
    }),
    ...mapActions("formulaire", {
      getLastFormCreatedByUser: "getLastFormCreatedByUser",
    }),
    ...mapActions("utils", {
      checkIFU: "checkIFU",
    }),
    ...mapActions("params", {
      action_loadDepartments: "loadDepartments",
      action_loadCommunes: "loadCommunes",
      action_loadArrondissements: "loadArrondissements",
      action_loadNeighborhoods: "loadNeighborhoods",
    }),
    ...mapActions("etablissements", {
      createEtablissement: "createEtablissement",
      action_getListTypesEtablissement: "getListTypesEtablissement",
    }),
    loadDepartments() {
      this.action_loadDepartments()
        .then((res) => {
          this.departements = res;
        })
        .catch((err) => {});
    },
    loadCommunes() {
      this.action_loadCommunes(this.form.departement)
        .then((res) => {
          this.communes = res;
        })
        .catch((err) => {});
    },
    loadArrondissements() {
      this.action_loadArrondissements(this.form.commune)
        .then((res) => {
          this.arrondissements = res;
        })
        .catch((err) => {});
    },
    loadNeighborhoods() {
      this.action_loadNeighborhoods(this.form.arrondissement)
        .then((res) => {
          this.quartiers = res;
        })
        .catch((err) => {});
    },
    getListTypesEtablissement() {
      this.action_getListTypesEtablissement()
        .then((res) => {
          // console.log("typeets", res);
          // this.typesEts = res.data.typeetablissements || [];
        })
        .catch((err) => {});
    },
    // showModal() {
    //   this.$refs[this.tipsModalRef].show();
    // },
    hideModal() {
      this.$refs[this.tipsModalRef].hide();
    },
    fillFormWithLastRecord() {
      this.isFillingForm = true;
      this.form = this.lastRecordedForm;
      setTimeout(() => {
        this.isFillingForm = false;
      }, 1000);
      this.hideModal();
      // console.log(this.form);
    },
    sendToPreview() {
      // this.$emit("onFormSucess", {
      //   routerParams: {
      //     preview: true,
      //     data: this.form,
      //   },
      // });
      // return

      this.$refs.formRules.validate().then(async (success) => {
        if (success) {
          let canSend = true;
          const isNotNull = (val) => {
            return val && val.trim() !== "";
          };
          const geValueFromInput = (inputField, fieldType) => {
            let result = "";
            if (fieldType === "radio") {
              result =
                inputField.radio && inputField.radio.hasManualInput
                  ? inputField.input
                  : inputField.radio?.value;
            } else {
              result = isNotNull(inputField.input)
                ? inputField.input
                : inputField.value;
            }
            return result;
          };
          const {
            motifVoyage,
            activiteBenin,
            moyenTransportArriveeBenin,
            moyenTransportBenin,
          } = this.form;
          let depensesAvantArrivee = {
            ...this.depensesAvantArrivee,
            options: this.depensesAvantArrivee.options.filter(
              (e) => e.selected
            ),
          };
          // clearing data
          const form = {
            ...this.form,
            // ...(this.form.modeHebergementBenin === "Autres" && {
            //   modeHebergementBenin: this.autreModeHebergementBenin,
            // }),
            motifVoyage: geValueFromInput(motifVoyage, "radio"),
            activiteBenin: activiteBenin.map((e) => {
              return e.input ? e.input : e.value;
            }),
            moyenTransportArriveeBenin: geValueFromInput(
              moyenTransportArriveeBenin,
              "radio"
            ),
            moyenTransportBenin: geValueFromInput(moyenTransportBenin, "radio"),
            depensesAvantArrivee: depensesAvantArrivee,
            depensesDurantSejour: this.depensesDurantSejour,
            nombreNuitParType: this.nombreNuitParType,
          };

          // if (form._id) delete form._id;

          // if (canSend) {
          // console.log(
          //   "------------------------- finale data form: ===> ",
          //   form
          // );
          this.$emit("onFormSucess", {
            routerParams: {
              preview: true,
              data: form,
            },
          });
        } else {
          const errorFirst = this.$el.querySelector(".is-invalid");
          // console.log(errorFirst);
          if (errorFirst) {
            // const errorFirstInput = errorFirst.querySelector('label')
            errorFirst.scrollIntoView({
              behavior: "instant",
            });
            errorFirst.focus();
          }
        }
      });
    },
    // loadPays() {
    //   this.pays = [{ name: "Bénin" }, { name: "Togo" }, { name: "Niger" }];
    // },
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/themes/bordered-layout.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>